/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-console */
import React from 'react'
import { Script, ScriptStrategy } from 'gatsby'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script
        src="https://www.clickcease.com/monitor/stat.js"
        strategy={ScriptStrategy.postHydrate}
      />
    </>
  )
}
