require('./src/global.css')

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

export const onRouteUpdate = () => {
  addScript(`//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`)
}

export { wrapPageElement } from './gatsby-shared'
